<template>
<div>
  <CRow class="info-box mt-2">
    <CCol md="6">
        <h3>
            <CIcon name="cib-telegram" style="color: green;" class="contact-icon" size="lg" />
            <span class="contact-name">Telegram</span>
        </h3>
    </CCol>
    <CCol md="4" class="info-box">
        <a href="https://t.me/ac_ludwig">@ac_ludwig</a>
    </CCol>
  </CRow>
  <CRow class="info-box mt-2">
    <CCol md="6">
        <h3>
            <CIcon name="cil-envelope-closed" style="color: blue;" class="contact-icon" size="lg" />
            <span class="contact-name">Email</span>
        </h3>
    </CCol>
    <CCol md="4" class="info-box">
        <a href="mailto:sales@asians.cloud">sales@asians.cloud</a>
    </CCol>
  </CRow>
  
  <CRow class="info-box mt-2">
    <CCol md="6">
        <h3>
            <CIcon name="cil-location-pin" style="color: blue;" class="contact-icon" size="lg" />
            <span class="contact-name">{{ $t('contact_us.OfficeAddressLabel') }}</span>
        </h3>
    </CCol>
    <CCol md="6" class="info-box">
        <p style="margin-bottom: 0">{{ $t('contact_us.OfficeAddress') }}</p>
    </CCol>
  </CRow>
</div>
</template>

<style scoped>
  .contact-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .contact-name {
    font-size: 1rem;
    margin-left: 10px;
  }

  .info-box {
    display: flex;
    align-items: center;
  }
</style>

<script>
export default {
  name: "Contacts",
  components: {
  }
};
</script>
