<template>
  <CDropdown inNav ref="cHeaderDropdown" class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <avatar :username="authInfo.email"></avatar>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $t('Account') }}</strong>
    </CDropdownHeader>

    <CDropdownItem>
      <CIcon name="cil-smile" />
      <CButton>{{ authInfo.email }}</CButton>
    </CDropdownItem>

    <CDropdownItem id="contact-support">
      <CIcon name="cil-phone" /><CButton @click="showContact()">{{ $t('contact_us.ContactCustomerService') }}</CButton>
    </CDropdownItem>

    <CDropdownItem>
      <CIcon name="cil-phone" /><CButton @click.stop="showGuide()">{{ $t('contact_us.ShowBoot') }}</CButton>
    </CDropdownItem>

    <CDropdownItem v-if="subscription">
      <CIcon name="cil-lock-locked" />
      {{ subscription.plan.nickname }}
    </CDropdownItem>

    <CDropdownItem v-on:click="logout">
      <CIcon name="cil-account-logout" />
      <CButton>{{ $t('Logout') }}</CButton>
    </CDropdownItem>

    
  </CDropdown>
</template>

<script>
import {OPT_OUT_NEW_ROOT_DOMAIN_STORAGE_KEY} from '@/utilities/constants';

var Avatar = require("vue-avatar");
import security from "@/rockt-vuejs-keycloak";
import { EventBus } from '@/plugins/event-bus.js';

export default {
  name: "KeycloakHeaderAccount",
  components: {
    avatar: Avatar.Avatar
  },
  data() {
    return {
      subscription: null,
    };
  },
  computed: {
    authInfo() {
      if (
        this.$store.state.security.auth &&
        this.$store.state.security.auth.authenticated
      ) {
        return this.$store.state.security.auth.tokenParsed;
      }
      return { preferred_username: "訪客" };
    },
    memberInfo() {
      if (
        this.$store.state.security.auth &&
        this.$store.state.security.auth.authenticated
      ) {
        return this.$store.state.security.auth.tokenParsed;
      }
      return { preferred_username: "訪客" };
    }
  },

  created() {
    // this.selectedDomain = newDomain;
    this.listenEvent();
  },

  methods: {
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on("show-header-dropdown", () => {
        this.$refs['cHeaderDropdown'].show = true
      });
    },
    logout() {
      window.localStorage.removeItem('OPT_OUT_NEW_DOMAIN_UI');
      window.localStorage.removeItem(OPT_OUT_NEW_ROOT_DOMAIN_STORAGE_KEY);
      
      security.logout();
      return false;
    },
    showContact() {
      EventBus.$emit('show-contact');
    },
    showGuide() {
      EventBus.$emit('show-guide');
    }
  },
  mounted() {
    // EventBus.$emit('show-contact');
  }
  // mounted() {
  //     this.fetchData();
  // },
  // fetchData () {
  //   if(this.$store.state.security.auth) {
  //     axios.get('plan/product/', {
  //       params: {
  //           "page": this.queryParams.page
  //       }
  //     })
  //     .then(function(response) {
  //       self.items = response.data.results;
  //       self.total_rows = response.data.count;
  //       self.showLoader = false;
  //     })
  //     .catch(function(error) {
  //       self.showLoader = false;
  //     });

  //     // $axios
  //     // .get('plan/product/')
  //     // .then(response => (this.subscription = response.data))

  //   }
  // }
};
</script>
