<template>
  <a-modal
      :title="$t('contact_us.ContactUs')"
      @cancel="handleCloseModal"
      :visible="contactModal"
      :zIndex="1009">
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol md="12">
            <div class="info-box">
              <i class="bx bx-map"></i>
              <h3>{{ $t('contact_us.contact_us_message') }}：</h3>
            </div>
          </CCol>
        </CRow>
        <Contacts/>
      </CCol>
    </CRow>

    <template slot="footer">
      <footer class="d-flex justify-content-between">
        <a-button @click="handleCloseModal" class="ml-auto">{{ $t('Close') }}</a-button>
      </footer>
    </template>
  </a-modal>
  <!-- End Contact Section -->
</template>

<script>
import {EventBus} from "@/plugins/event-bus.js";
import Contacts from './Contacts'

export default {
  name: "TheContact",
  components: {
    Contacts,
  },
  data() {
    return {
      contactModal: false
    };
  },
  props: {},
  methods: {
    listenEvent() {
      // Send the event on a channel (i-got-clicked) with a payload (the click count.)
      EventBus.$on("show-contact", () => {
        this.contactModal = true;
      });
    },
    handleCloseModal() {
      this.contactModal = false;
    }
  },
  created() {
    // this.selectedDomain = newDomain;
    this.listenEvent();
  }
};
</script>
