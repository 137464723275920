<template>
  <a-popover placement="bottom" trigger="click" @visibleChange="handleShow">
    <template slot="content">
      <a-list size="large" bordered :data-source="notifications">
        <a-list-item slot="renderItem" slot-scope="notification, index">
          {{ notification }}
        </a-list-item>
      </a-list>
      <a-list size="large" bordered :data-source="tasks">
        <a-list-item slot="renderItem" slot-scope="task, index">
          {{ taskTitleToTranslation(task.name) }}
          <div style="width:200px" v-if="task.status === 'PROGRESS'">
            <a-progress :percent="task.result.percent" status="active"/>
          </div>
          <div style="width:200px" v-if="task.status === 'SUCCESS'">
            <a-progress :percent="100"/>
          </div>
          <div style="width:200px" v-if="task.status === 'FAILURE'">
            <a-progress :percent="100" status="exception"/>
          </div>
          <span class="task-description text-muted" v-if="task.status !== 'FAILURE'">
            {{ task.result.description }}
          </span>
          <span class="task-description text-muted" v-if="task.status !== 'PROGRESS'">
            <br/> {{ formatDateToLocal(task.date_done) }}
          </span>
        </a-list-item>
      </a-list>
    </template>
    <a-tooltip placement="left"
               :title="tooltipMessage"
               :visible="tooltipVisible"
               :getPopupContainer="handleGetPopupContainer">
      <a-button type="link" ref="flagButton">
        <a-icon type="flag"/>
      </a-button>
    </a-tooltip>
  </a-popover>
</template>

<script>
import {parseISO, addMinutes, subMinutes, formatDistance} from 'date-fns';
import { getUserTasks, getUserNotification } from '@/utilities/api';
import {EventBus} from '@/plugins/event-bus';
import {EVENT_BUS_EVENTS, TASK_NAMES} from '@/utilities/constants';
import { tr } from 'date-fns/locale';

const refreshInterval = 5000;

export default {
  data() {
    return {
      tasks: [],
      visible: false,
      tooltipVisible: false,
      tooltipMessage: '',
      notifications: []
    }
  },
  mounted() {
    EventBus.$on(EVENT_BUS_EVENTS.TASK_SCHEDULED, (data) => {
      if (data.task === TASK_NAMES.CLEAR_CLEAR) {
        this.tooltipMessage = this.$t('message.ClearCacheScheduled');
      } else if (data.task === TASK_NAMES.FORCE_HTTPS && data.options) {
        if (data.options.enabled) {
          this.tooltipMessage = this.$t('message.ForceHTTPSEnabledScheduled');
        } else if (!data.options.enabled) {
          this.tooltipMessage = this.$t('message.ForceHTTPSDisabledScheduled');
        }
      } else {
        this.tooltipMessage = this.$t('message.TaskScheduled');
      }

      this.tooltipVisible = true;
      this.refresh();

      setTimeout(() => {
        this.tooltipVisible = false;
      }, 8000);
    });
  },
  methods: {
    formatDateToLocal(dateStr) {
      const offset = (new Date()).getTimezoneOffset();

      let localeTime = parseISO(dateStr);
      if (offset < 0) {
        localeTime = subMinutes(localeTime, offset);
      } else {
        localeTime = addMinutes(localeTime, offset);
      }

      return formatDistance(localeTime, new Date()) + ' ago'
    },
    taskTitleToTranslation(taskName) {
      return this.$t(taskName);
    },
    handleShow(visible) {
      this.visible = visible;
      if (visible) {
        this.refresh();
      }
    },
    async getNotifications() {
      let result = await getUserNotification();
      this.notifications = result.data.user_notifications;
    },
    async refresh() {
      if (!this.visible) {
        return;
      }

      try {
        this.getNotifications();
      } catch (e) {

      }

      let result;
      try {
        result = await getUserTasks();
      } catch (e) {
        this.$message.info(this.$t('ErrorOccurred'));
        return;
      }

      this.tasks = result.data;

      setTimeout(() => {
        this.refresh();
      }, refreshInterval);
    },
    handleGetPopupContainer() {
      // attach the popup to the button so if the page scrolls the tooltip
      // will stay in place. attaching the popup to the body (the default behavior)
      // will move the tooltip whenever the page scrolls.
      return this.$refs.flagButton.$el;
    },
  }
}
</script>

<style scoped>
span.task-description {
  font-size: 10px;
}
</style>